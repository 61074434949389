/* Add this CSS to your project to extend the existing Swiper classes */
.swiper-button-prev,
.swiper-button-next {
  border-radius: 50%;
  padding-left: 20px;
  padding-right: 20px;
  background-clip: padding-box;
  color: black;
  background-color: rgba(255, 255, 255, 0.9);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 0 1px transparent, 0 0 0 4px transparent, 0 2px 4px rgba(0, 0, 0, 0.18);
}

.swiper-button-prev,
.swiper-button-next:hover {
  background-color: #ffffff;
  box-shadow: 0 0 0 1px transparent, 0 0 0 4px transparent, 0 2px 4px rgba(0, 0, 0, 0.12);
  transform: scale(1.04);
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 14px;
  font-weight: 900;
  color: black;
}